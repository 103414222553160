<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <!-- <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div
              class="navbar-brand brand-logo text-center navbar-brand-wrapper d-flex align-items-top justify-content-center"
            >
              <img src="@/assets/images/logopay.png" alt="profile" style="width: 100%; height: 100%" />
              
            </div>
          </a>
        </li> -->

        <li class="nav-item font-weight-500" v-on:click="collapseAll">
          <router-link class="nav-link" to="/dashboard">
            <span class="menu-title">Tableau de board</span>

            <i class="mdi mdi-home menu-icon text-white"></i>
            <!-- 
            <i class="mdi menu-icon special-style-icon"
              ><img
                src="@/assets/images/dashboard.png"
                alt="patient"
                style="width: 1rem; height: 1rem"
              />
            </i> -->
          </router-link>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_paramètre')" -->
          <span class="nav-link" v-b-toggle="'ui-basic'">
            <span class="menu-title">Paramètrage</span>

            <i class="menu-arrow"></i>
            <!-- 
            <img
              src="@/assets/images/setting.png"
              alt="patient"
              style="width: 1rem; height: 1rem"
            /> -->
          </span>

          <b-collapse accordion="sidebar-accordion" id="ui-basic">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_utilisateurs')" -->

                <router-link class="nav-link" to="/user/list"
                  >Utilisateurs</router-link
                >
              </li>
              <!-- <li class="nav-item">

                <router-link class="nav-link" to="/user/role/list">Rôle Utilisateur</router-link>

              </li> -->
              <!-- <li class="nav-item">

                <router-link class="nav-link" to="/user/role/list">Rôle Utilisateur</router-link>

              </li> -->

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_droit_utilisateurs')" -->
                <router-link class="nav-link" to="/user/role/permissions"
                  >Droits utilisateurs</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_affecterterminal')" -->
                <router-link class="nav-link" to="/affectterminal/list"
                  >AffectTerminal</router-link
                >
              </li>
              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_terminal')" -->
                <router-link class="nav-link" to="/terminal/list"
                  >Terminal</router-link
                >
              </li>
              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_type_centre')" -->
                <router-link class="nav-link" to="/ephe/list"
                  >Type centre</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_carte_sanitaire')" -->
                <router-link class="nav-link" to="/eph/list"
                  >Carte Sanitaire</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_acte_medicaux')" -->
                <router-link class="nav-link" to="/actes-medicaux/list"
                  >Actes médicaux</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_indigence')" -->
                <router-link class="nav-link" to="/indigence/list"
                  >Indigences</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_compagnie')" -->
                <router-link class="nav-link" to="/compagnie/list"
                  >Compagnie</router-link
                >
              </li>

              <li class="nav-item">
                <!-- v-if="permission.includes('Voir_module_assurance_compagnie')" -->
                <router-link class="nav-link" to="/assurance/list"
                  >Assurance Compagnie</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <!-- v-if="permission.includes('Voir_module_patient')" -->
        <!-- <li class="nav-item" v-if="permission.includes('Voir_module_patient')"> -->
        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_patient')" -->
          <router-link
            class="nav-link"
            to="/patients/list"
            style="text-decoration: none"
          >
            <!-- <span class="menu-title">Gestion des patients</span> -->

            <span class="menu-title">Gestion des patients</span>
            <!-- 
            <i class="mdi menu-icon special-style-icon"
              ><img
                src="@/assets/images/patients.png"
                alt="patient"
                style="width: 1rem; height: 1rem"
              />
            </i> -->
          </router-link>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_assurance_patient')" -->
          <!-- v-if="permission.includes('Voir_module_assurance_patient')" -->

          <router-link
            class="nav-link"
            to="/priseencharge/list"
            style="text-decoration: none"
          >
            <span class="menu-title"> Assurance patient </span>

            <!-- <i class="mdi menu-icon special-style-icon"
              ><img
                src="@/assets/images/patient.png"
                alt="patient"
                style="width: 1rem; height: 1rem"
              />
            </i> -->

            <!-- <i class="menu-arrow"></i> -->
          </router-link>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_indigence_patient')" -->
          <router-link
            class="nav-link"
            to="/indigence-patient/list-indigence-patient"
            style="text-decoration: none"
          >
            <span class="menu-title">Indigence Patient</span>

            <!-- <i class="mdi menu-icon special-style-icon"
              ><img
                src="@/assets/images/patient.png"
                alt="patient"
                style="width: 1rem; height: 1rem"
            /></i> -->

            <!-- <i class="menu-arrow"></i> -->
          </router-link>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_paiement_et_facturation')" -->
          <!-- v-if="permission.includes('Voir_module_paiement_et_facturation')" -->

          <router-link
            class="nav-link"
            to="/payement/list"
            style="text-decoration: none"
          >
            <!-- <span class="menu-title">Paiement et <br />Facturation</span> -->
            <span class="menu-title">Paiement et Facturation</span>

            <!-- <i class="mdi menu-icon special-style-icon"
              ><img
                src="@/assets/images/pay.png"
                alt="patient"
                style="width: 1rem; height: 1rem"
            /></i> -->

            <!-- <i class="menu-arrow"></i> -->
          </router-link>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_tresorier')" -->
          <!-- v-if="permission.includes('Voir_module_tresorier')" -->
          <span class="nav-link" v-b-toggle="'tresorerie'">
            <span class="menu-title">Trésorerie</span>

            <i class="menu-arrow"></i>

            <!-- <img
              src="@/assets/images/coins.png"
              alt="sid"
              style="width: 1rem; height: 1rem"
            /> -->
          </span>

          <b-collapse accordion="sidebar-accordion" id="tresorerie">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/tresorerie/list-encaissement"
                  >Encaissement</router-link
                >
              </li>

              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/tresorerie/list-decaissement"
                >
                  Décaissement</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <!-- <li class="nav-item" v-if="permission.includes('Voir_module_rapport')"> -->
        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_rapport')" -->
          <!-- v-if="permission.includes('Voir_module_tresorier')" -->
          <span class="nav-link" v-b-toggle="'rapport'">
            <span class="menu-title">Rapports journaliers</span>

            <i class="menu-arrow"></i>

            <!-- <img
              src="@/assets/images/report.png"
              alt="sid"
              style="width: 1rem; height: 1rem"
            /> -->
          </span>

          <b-collapse accordion="sidebar-accordion" id="rapport">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/rapport"
                  >Rapport Hôpital</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/rapport/caissier"
                  >Rapport Caissier</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/rapport/terminal"
                  >Rapport Terminal</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item">
          <!-- v-if="permission.includes('Voir_module_rapport_general')" -->
          <!-- v-if="permission.includes('Voir_module_tresorier')" -->
          <span class="nav-link" v-b-toggle="'rapports'">
            <span class="menu-title">Rapport Général</span>

            <i class="menu-arrow"></i>

            <!-- <img
              src="@/assets/images/report.png"
              alt="sid"
              style="width: 1rem; height: 1rem"
            /> -->
          </span>

          <b-collapse accordion="sidebar-accordion" id="rapports">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/rapport-general/hospital"
                  >Rapport Hôpital</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/rapport-general/geospatial"
                  >Rapport Géospatial
                </router-link>
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="sidebar-actions">
          <!-- <span class="nav-link" v-on:click="logout"> -->
          <button
            class="btn btn-block pt-3 mx-auto text-center"
            style="background-color: #eadb9a"
          >
            <i class="mdi mdi-power ok"></i>
            <span style="color: rgb(12, 1, 1) !important; font-weight: bold"
              >Déconnexion</span
            >
          </button>
          <!-- </span> -->
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import { LoginService, setLoggedIn } from "@/_services";

export default {
  name: "sidebar",
  data() {
    return {
      // user: JSON.parse(localStorage.getItem("user")),
      // permission: JSON.parse(localStorage.getItem("permission")),
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    // logout() {
    //   LoginService.logout();
    // },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>

<style>
/* .bien{
  filter: brightness(100%);
  } */

.special-style-icon {
  color: white !important;
}

.nav-item,
.nav-link {
  /* font-weight: bold; */
  /* font-size: 18px !important;  */
  font-family: "Montserrat";
}

.ok {
  font-weight: bold !important;
  color: black !important;
}
</style>
