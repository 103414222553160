
import Vue from "vue";
import Router from "vue-router";
import App from "../App.vue";

import layout from "../layout";
import mvtlayout from "../mvtlayout";

import serviceLayout from "../pages/actes-medicaux/layout/index-service";

import layoutPatient from "../pages/module-patient/layout/index-patient";
import sidebarAssurance from "../pages/module-assurance/layout/index-assurance";

import layoutPayment from "../pages/module-payment/layout/index-payment";
import layoutCaisse from "../pages/module-caisse/layout/index-caisse";
import layoutStock from "../pages/module-stock/layout/index-stock";
import layoutPharmacy from "../pages/module-pharmacy/layout/index-pharmacy";
import layoutConfiguration from "../pages/configuration/layout/index-configuration";
import layoutGRH from "../pages/module-GRH/layout/index-grh";
import layout2 from "../layout-2";
// import layout3 from "../Layout-3";
import accueil from "../pages/accueil/accueil.vue";
base: "/",
Vue.use(Router);

const router = new Router({

    linkExactActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",

    routes: [

    {
        path: "/",
        component: accueil,
    },


    {
        path: "/dashboard",
        component: layout,
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
            import ("@/pages/dashboard"),
        }],
    },

    {
        path: "/basic-ui",
        component: layout,
        children: [{
            path: "buttons",
            name: "buttons",
            component: () =>
            import ("@/pages/basic-ui/buttons"),
        },
        {
            path: "dropdowns",
            name: "dropdowns",
            component: () =>
            import ("@/pages/basic-ui/dropdowns"),
        },
        {
            path: "typography",
            name: "typography",
            component: () =>
            import ("@/pages/basic-ui/typography"),
        },
        ],
    },
    {
        path: "/charts",
        component: layout,
        children: [{
            path: "chartjs",
            name: "chartjs",
            component: () =>
            import ("@/pages/charts/chartjs"),
        }, ],
    },
    {
        path: "/tables",
        component: layout,
        children: [{
            path: "basic-tables",
            name: "basic-tables",
            component: () =>
            import ("@/pages/tables/basic-tables"),
        }, ],
    },
    {
        path: "/auth-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "login",
            name: "login",
            component: () =>
            import ("@/pages/samples/auth-pages/login"),
        },
        {
            path: "register",
            name: "register",
            component: () =>
            import ("@/pages/samples/auth-pages/register"),
        },
        {
            path: "reset",
            name: "reset",
            component: () =>
            import ("@/pages/samples/auth-pages/reset-password"),
        },
        {
            path: "forget-password",
            name: "forget-password",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password"),
        },
        {
            path: "forget-password-confirm",
            name: "forget-password-confirm",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password-confirm"),
        },
        ],
    },
    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            name: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        },
        {
            path: "error-500",
            name: "error-500",
            component: () =>
            import ("@/pages/samples/error-pages/error-500"),
        },
        ],
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },

    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },
    {
        path: "/dashboard",
        component: layout,
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
            import ("@/pages/dashboard"),
        }, ],
    },
    {
        path: "/basic-ui",
        component: layout,
        children: [{
            path: "buttons",
            name: "buttons",
            component: () =>
            import ("@/pages/basic-ui/buttons"),
        },
        {
            path: "dropdowns",
            name: "dropdowns",
            component: () =>
            import ("@/pages/basic-ui/dropdowns"),
        },
        {
            path: "typography",
            name: "typography",
            component: () =>
            import ("@/pages/basic-ui/typography"),
        },
        ],
    },
    {
        path: "/charts",
        component: layout,
        children: [{
            path: "chartjs",
            name: "chartjs",
            component: () =>
            import ("@/pages/charts/chartjs"),
        }, ],
    },
    {
        path: "/tables",
        component: layout,
        children: [{
            path: "basic-tables",
            name: "basic-tables",
            component: () =>
            import ("@/pages/tables/basic-tables"),
        }, ],

    },
    {

        path: "/auth-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "login",
            name: "login",
            component: () =>
            import ("@/pages/samples/auth-pages/login"),
        },
        {
            path: "register",
            name: "register",
            component: () =>
            import ("@/pages/samples/auth-pages/register"),
        },
        {
            path: "reset",
            name: "reset",
            component: () =>
            import ("@/pages/samples/auth-pages/reset-password"),
        },
        {
            path: "forget-password",
            name: "forget-password",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password"),
        },
        {
            path: "forget-password-confirm",
            name: "forget-password-confirm",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password-confirm"),
        },
        ],
    },
    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            name: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        },
        {
            path: "error-500",
            name: "error-500",
            component: () =>
            import ("@/pages/samples/error-pages/error-500"),
        },
        ],
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },

    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-GRH/grh/detail"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/grh/create"),
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/priseencharge/create"),
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/priseencharge/index"),
        }, ],

    },
    {

        path: "/home",
        component: layout2,
        children: [{
            path: "",
            name: "home",
            component: () =>
            import ("@/pages/dashboard/home"),
        }, ],
    },


        // {
        //     path: "/accueil",
        //     component: accueil,
        // },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "create",
            name: "create-payement",
            component: () =>
            import ("@/pages/module-payment/payment/create"),
        }, ],

    },
    {

        path: "/payement",
        component: () =>
        import ("@/pages/module-payment/payment/select-cash-register"),
        children: [{
            path: "cash-register",
            name: "cash-register",
            component: () =>
            import ("@/pages/module-payment/payment/select-cash-register"),
        }, ],
    },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "list",
            name: "list-payement",
            component: () =>
            import ("@/pages/module-payment/payment/index"),
        }, ],
    },
    {

        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "caisse",
            name: "caisse-payement",
            component: () =>
            import ("@/pages/module-payment/payment/caisse"),
        }, ],

    },
    {
        path: "error-500",
        name: "error-500",
        component: () =>
        import ("@/pages/samples/error-pages/error-500"),
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },

    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-GRH/grh/detail"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/grh/create"),
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/priseencharge/create"),
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/priseencharge/index"),
        }, ],
    },

    {
        path: "/",
        component: layout2,
        children: [{
            path: "",
            name: "home",
            component: () =>
            import ("@/pages/dashboard/home"),
        }, ],
    },


        // {
        //     path: "/accueil",
        //     component: accueil,
        // },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "create",
            name: "create-payement",
            component: () =>
            import ("@/pages/module-payment/payment/create"),
        }, ],
    },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "list",
            name: "list-payement",
            component: () =>
            import ("@/pages/module-payment/payment/index"),
        }, ],
    },
    {

        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "caisse",
            name: "caisse-payement",
            component: () =>
            import ("@/pages/module-payment/payment/caisse"),
        }, ],
    },
    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "caisse",
            name: "caisse",
            component: () =>
            import ("@/pages/module-caisse/caisse/caisse"),
        }, ],
    },



        // {
        //   path: "/facture/details/:id(\\d+)",
        //   name: "FactureDetails",
        //   component: () => import("@/pages/paiement/details-facture"),
        //   props: true,
        // },

    {
        path: "/facture/details/:reference",
        name: "FactureDetails",
        component: () =>
        import ("@/pages/module-payment/payment/details-facture"),
        props: true,
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "create",
            name: "create-caisse",
            component: () =>
            import ("@/pages/module-caisse/caisse/create"),
        }, ],
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "bordereaux-transfert",
            name: "bordereaux-transfert",
            component: () =>
            import ("@/pages/module-caisse/caisse/bordereaux-tranfert"),
        }, ],
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "rapport",
            name: "rapport-caisse",
            component: () =>
            import ("@/pages/module-caisse/caisse/rapport"),
        }, ],
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "create",
            name: "create-caisse",
            component: () =>
            import ("@/pages/module-caisse/caisse/create"),
        }, ],
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "bordereaux-transfert",
            name: "bordereaux-transfert",
            component: () =>
            import ("@/pages/module-caisse/caisse/bordereaux-tranfert"),
        }, ],
    },

    {
        path: "/caisse",
        component: layoutCaisse,
        children: [{
            path: "rapport",
            name: "rapport-caisse",
            component: () =>
            import ("@/pages/module-caisse/caisse/rapport"),
        }, ],
    },


    {
        path: "/eph",
        component: layout,
        children: [{
            path: "create",
            name: "eph-create",
            component: () =>
            import ("@/pages/centre-sante/create"),
        }, ],
    },

    {
        path: "/eph",
        component: layout,
        children: [{
            path: "list",
            name: "eph-list",
            component: () =>
            import ("@/pages/centre-sante/index"),
        }, ],
    },

    {
        path: "/eph",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "eph-update",
            component: () =>
            import ("@/pages/centre-sante/update"),
            props: true,
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "create",
            name: "affectterminal-create",
            component: () =>
            import ("@/pages/affectterminal/create"),
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "list",
            name: "affectterminal-list",
            component: () =>
            import ("@/pages/affectterminal/index"),
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "affectterminal-update",
            component: () =>
            import ("@/pages/affectterminal/update"),
            props: true,
        }, ],
    },
    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "create",
            name: "terminal-create",
            component: () =>
            import ("@/pages/terminal/create"),
        }, ],
    },

    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "list",
            name: "terminal-list",
            component: () =>
            import ("@/pages/terminal/index"),
        }, ],
    },

    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "terminal-update",
            component: () =>
            import ("@/pages/terminal/update"),
            props: true,
        }, ],
    },
        // {
        //   path: "/profile",
        //   component: layout,
        //   children: [
        //     {
        //       path: "/:id",
        //       name: "profile-update",
        //       component: () => import("@/pages/profile/profileedit"),
        //       // props: true,
        //     },
        //   ],
        // },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "profileedit/:id",
            name: "profile-update",
            component: () =>
            import ("@/pages/profile/profileedit"),
            props: true,
        }, ],
    },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "create",
            name: "profile-create",
            component: () =>
            import ("@/pages/profile/profilepasswordedit"),
        }, ],
    },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "list",
            name: "profile-list",
            component: () =>
            import ("@/pages/profile/profileshow"),
        }, ],
    },

        // {
        //   path: "/profile",
        //   component: layout,
        //   children: [
        //     {
        //       path: "update/:id(\\d+)",
        //       name: "profile-update",
        //       component: () => import("@/pages/profile/update"),
        //       props: true,
        //     },
        //   ],
        // },
    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "create",
            name: "ephe-create",
            component: () =>
            import ("@/pages/typecentre/create"),
        }, ],
    },

    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "list",
            name: "ephe-list",
            component: () =>
            import ("@/pages/typecentre/index"),
        }, ],
    },

    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "ephe-update",
            component: () =>
            import ("@/pages/typecentre/update"),
            props: true,
        }, ],
    },

        //---------------------------------------Start Pharmacy Routes---------------------------------------
    {
        path: "/sale",
        component: layoutPharmacy,
        children: [{
            path: "create",
            name: "sale.create",
            component: () =>
            import ("@/pages/module-pharmacy/sale/create"),
        }, ],
    },
    {
        path: "/sale",
        component: layoutPharmacy,
        children: [{
            path: "list",
            name: "sale.list",
            component: () =>
            import ("@/pages/module-pharmacy/sale/index"),
        }, ],
    },
    {
        path: "/destock",
        component: layoutPharmacy,
        children: [{
            path: "create",
            name: "destock.list",
            component: () =>
            import ("@/pages/module-pharmacy/destock/create"),
        }, ],
    },
        //---------------------------------------End Pharmacy Routes---------------------------------------

        //---------------------------------------Start Configuration Routes---------------------------------------
        //------------------Start Configuration Stock Routes------------------
        //-------------------------Store-------------------------
    {
        path: "/configuration/store",
        component: layoutConfiguration,
        children: [{
            path: "list",
            name: "store.list",
            component: () =>
            import ("@/pages/configuration/stock/store/index"),
        }, ],
    },
        //-------------------------Stock-------------------------
    {
        path: "/configuration/stock",
        component: layoutConfiguration,
        children: [{
            path: "list",
            name: "stock.list",
            component: () =>
            import ("@/pages/configuration/stock/stock/index"),
        }, ],
    },
        //-------------------------Category-------------------------
    {
        path: "/configuration/category",
        component: layoutConfiguration,
        children: [{
            path: "list",
            name: "category.list",
            component: () =>
            import ("@/pages/configuration/stock/category/index"),
        }, ],
    },
        //-------------------------Supplier-------------------------
    {
        path: "/configuration/supplier",
        component: layoutConfiguration,
        children: [{
            path: "list",
            name: "supplier.list",
            component: () =>
            import ("@/pages/configuration/stock/supplier/index"),
        }, ],
    },
        //------------------End Configuration Stock Routes------------------
        //---------------------------------------End Configuration Routes---------------------------------------

        //---------------------------------------Start Stock Routes---------------------------------------
        //-------------------------Store Stocks-------------------------
    {
        path: "/magasin-stocks",
        component: layoutStock,
        children: [{
            path: "list",
            name: "magasin-stocks.list",
            component: () =>
            import ("@/pages/module-stock/store-stock/index"),
        }, ],
    },

    {
        path: "/magasin-stocks",
        component: layoutStock,
        children: [{
            path: ":uuid/details",
            name: "magasin-stocks.details",
            component: () =>
            import ("@/pages/module-stock/store-stock/movements"),
            props: true,
        }, ],
    },
    {
        path: "/magasin-stocks",
        component: layoutStock,
        children: [{
            path: ":uuid/",
            name: "magasin-stocks.details",
            component: () =>
            import ("@/pages/module-stock/store-stock/details"),
            props: true,
        }, ],
    },
        //-------------------------Drugs-------------------------

    {
        path: "/medicaments",
        component: layoutStock,
        children: [{
            path: "create",
            name: "medicaments.create",
            component: () =>
            import ("@/pages/module-stock/medicaments/create"),
        }, ],
    },

    {
        path: "/medicaments",
        component: layoutStock,
        children: [{
            path: "list",
            name: "medicaments.list",
            component: () =>
            import ("@/pages/module-stock/medicaments/index"),
        }, ],
    },

    {
        path: "/medicaments",
        component: layoutStock,
        children: [{
            path: "update/:uuid",
            name: "medicaments.update",
            component: () =>
            import ("@/pages/module-stock/medicaments/update"),
            props: true,
        }, ],
    },
    {
        path: "/medicaments",
        component: layoutStock,
        children: [{
            path: ":uuid/movements",
            name: "medicaments.movements",
            component: () =>
            import ("@/pages/module-stock/medicaments/movements"),
            props: true,
        }, ],
    },
    {
        path: "/medicaments",
        component: layoutStock,
        children: [{
            path: ":uuid/details",
            name: "medicaments.details",
            component: () =>
            import ("@/pages/module-stock/medicaments/details"),
            props: true,
        }, ],
    },

        //-------------------------Notebooks and cards-------------------------
    {
        path: "/carnets-cartes",
        component: layoutStock,
        children: [{
            path: "create",
            name: "carnets_cartes.create",
            component: () =>
            import ("@/pages/module-stock/carnets-cartes/create"),
        }, ],
    },

    {
        path: "/carnets-cartes",
        component: layoutStock,
        children: [{
            path: "list",
            name: "carnets_cartes.list",
            component: () =>
            import ("@/pages/module-stock/carnets-cartes/index"),
        }, ],
    },

    {
        path: "/carnets-cartes",
        component: layoutStock,
        children: [{
            path: "update/:uuid",
            name: "carnets_cartes.update",
            component: () =>
            import ("@/pages/module-stock/carnets-cartes/update"),
            props: true,
        }, ],
    },
    {
        path: "/carnets-cartes",
        component: layoutStock,
        children: [{
            path: ":uuid/details",
            name: "carnets-cartes.details",
            component: () =>
            import ("@/pages/module-stock/carnets-cartes/details"),
            props: true,
        }, ],
    },

        //-------------------------Consumables-------------------------
    {
        path: "/consommables",
        component: layoutStock,
        children: [{
            path: "create",
            name: "consommables.create",
            component: () =>
            import ("@/pages/module-stock/consommables/create"),
        }, ],
    },

    {
        path: "/consommables",
        component: layoutStock,
        children: [{
            path: "list",
            name: "consommables.list",
            component: () =>
            import ("@/pages/module-stock/consommables/index"),
        }, ],
    },

    {
        path: "/consommables",
        component: layoutStock,
        children: [{
            path: "update/:uuid",
            name: "consommables.update",
            component: () =>
            import ("@/pages/module-stock/consommables/update"),
            props: true,
        }, ],
    },


    {
        path: "/consommables",
        component: layoutStock,
        children: [{
            path: ":uuid/details",
            name: "consommables.details",
            component: () =>
            import ("@/pages/module-stock/consommables/details"),
            props: true,
        }, ],
    },

        //-------------------------ProductSpecification-------------------------
    {
        path: "/product-specification",
        component: layoutStock,
        children: [{
            path: "create",
            name: "product_specification.create",
            component: () =>
            import ("@/pages/module-stock/productspecification/create"),
        }, ],
    },

    {
        path: "/product-specification",
        component: layoutStock,
        children: [{
            path: "list",
            name: "product_specification.list",
            component: () =>
            import ("@/pages/module-stock/productspecification/index"),
        }, ],
    },

    {
        path: "/product-specification",
        component: layoutStock,
        children: [{
            path: "update/:uuid",
            name: "product_specification.update",
            component: () =>
            import ("@/pages/module-stock/productspecification/update"),
            props: true,
        }, ],
    },

        //-------------------------Supply-------------------------

    {
        path: "/supply",
        component: layoutStock,
        children: [{
            path: "create",
            name: "supply.create",
            component: () =>
            import ("@/pages/module-stock/supply/create"),
        }, ],
    },

    {
        path: "/supply",
        component: layoutStock,
        children: [{
            path: "list",
            name: "supply.list",
            component: () =>
            import ("@/pages/module-stock/supply/index"),
        }, ],
    },

    {
        path: "/supply",
        component: layoutStock,
        children: [{
            path: ":uuid/products",
            name: "supply.details",
            component: () =>
            import ("@/pages/module-stock/supply/details"),
            props: true,
        }, ],
    },

        //-------------------------Transert-------------------------
    {
        path: "/transfert",
        component: layoutStock,
        children: [{
            path: "create",
            name: "transfert.create",
            component: () =>
            import ("@/pages/module-stock/transfert/create"),
        }, ],
    },

    {
        path: "/transfert",
        component: layoutStock,
        children: [{
            path: "list",
            name: "transfert.list",
            component: () =>
            import ("@/pages/module-stock/transfert/index"),
        }, ],
    },

    {
        path: "/transfert",
        component: layoutStock,
        children: [{
            path: ":uuid/products",
            name: "transfert.details",
            component: () =>
            import ("@/pages/module-stock/transfert/details"),
            props: true,
        }, ],
    },

        //-------------------------Stocks-------------------------
    {
        path: "/consulter/produits",
        component: layoutStock,
        children: [{
            path: "stock",
            name: "stocks.list",
            component: () =>
            import ("@/pages/module-stock/stocks/create"),
        }, ],
    },
        //---------------------------------------End Stock Routes---------------------------------------
    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "create",
            name: "actes-create",
            component: () =>
            import ("@/pages/actes-medicaux/create"),
        }, ],
    },
    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "list",
            name: "actes-list",
            component: () =>
            import ("@/pages/actes-medicaux/index"),
        }, ],
    },
    {
        path: "/assurance",
        component: sidebarAssurance,
        children: [{
            path: "create",
            name: "assurance-create",
            component: () =>
            import ("@/pages/assurance/create"),
        }, ],
    },




    {
        path: "/assurance",
        component: sidebarAssurance,
        children: [{
            path: "list",
            name: "assurance-list",
            component: () =>
            import ("@/pages/assurance/index"),
        }, ],
    },
    {
        path: "/compagnie",
        component: layout,
        children: [{
            path: "create",
            name: "compagnie-create",
            component: () =>
            import ("@/pages/compagnie/create"),
        }, ],
    },
    {
        path: "/compagnie",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "compagnie-list",
            component: () =>
            import ("@/pages/compagnie/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "create",
            name: "users-create",
            component: () =>
            import ("@/pages/user/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "list",
            name: "users-list",
            component: () =>
            import ("@/pages/user/index"),
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/priseencharge/update"),
            props: true,
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "create",
            name: "users-create",
            component: () =>
            import ("@/pages/user/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "list",
            name: "users-list",
            component: () =>
            import ("@/pages/user/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/update"),
            props: true,
        }, ],
    },
    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/create",
            name: "role-create",
            component: () =>
            import ("@/pages/user/role/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/list",
            name: "role-list",
            component: () =>
            import ("@/pages/user/role/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/permissions",
            name: "role-permissions",
            component: () =>
            import ("@/pages/user/permissions/index"),
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "encaissement",
            name: "encaissement-create",
            component: () =>
            import ("@/pages/tresorerie/encaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement",
            name: "decaissement-create",
            component: () =>
            import ("@/pages/tresorerie/decaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-encaissement",
            name: "list-encaissement",
            component: () =>
            import ("@/pages/tresorerie/encaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-decaissement",
            name: "list-decaissement",
            component: () =>
            import ("@/pages/tresorerie/decaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement/update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/decaissement/update"),
            props: true,
        }, ],
    },
    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "indigence-patient-create",
            name: "indigence-patient-create",
            component: () =>
            import ("@/pages/indigence-patient/create"),
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "list-indigence-patient",
            name: "list-indigence-patient",
            component: () =>
            import ("@/pages/indigence-patient/index"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "create",
            name: "indigence",
            component: () =>
            import ("@/pages/indigence/create"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "list",
            name: "list-indigence",
            component: () =>
            import ("@/pages/indigence/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport",
            name: "rapport",
            component: () =>
            import ("@/pages/rapport/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/caissier",
            name: "rapport-caissier",
            component: () =>
            import ("@/pages/rapport/caissier"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/hospital",
            name: "rapports-hospital",
            component: () =>
            import ("@/pages/rapport-general/hospital"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/geospatial",
            name: "rapports-geospatial",
            component: () =>
            import ("@/pages/rapport-general/geospatial"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/terminal",
            name: "rapport-terminal",
            component: () =>
            import ("@/pages/rapport/terminal"),
        }, ],
    },

    {
        path: "/compagnie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/compagnie/update"),
            props: true,
        }, ],
    },

    {
        path: "/assurance",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/assurance/update"),
            props: true,
        }, ],
    },

    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/actes-medicaux/update"),
            props: true,
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/role/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence-patient/update"),
            props: true,
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/encaissement/update"),
            props: true,
        }, ],
    },

    {
        path: "*",
        redirect: "/error-404",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        }, ],
    },


    {
        path: "/dashboard",
        component: layout,
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
            import ("@/pages/dashboard"),
        }, ],
    },
    {
        path: "/basic-ui",
        component: layout,
        children: [{
            path: "buttons",
            name: "buttons",
            component: () =>
            import ("@/pages/basic-ui/buttons"),
        },
        {
            path: "dropdowns",
            name: "dropdowns",
            component: () =>
            import ("@/pages/basic-ui/dropdowns"),
        },
        {
            path: "typography",
            name: "typography",
            component: () =>
            import ("@/pages/basic-ui/typography"),
        },
        ],
    },
    {
        path: "/charts",
        component: layout,
        children: [{
            path: "chartjs",
            name: "chartjs",
            component: () =>
            import ("@/pages/charts/chartjs"),
        }, ],
    },
    {
        path: "/tables",
        component: layout,
        children: [{
            path: "basic-tables",
            name: "basic-tables",
            component: () =>
            import ("@/pages/tables/basic-tables"),
        }, ],
    },
    {
        path: "/auth-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "login",
            name: "login",
            component: () =>
            import ("@/pages/samples/auth-pages/login"),
        },
        {
            path: "register",
            name: "register",
            component: () =>
            import ("@/pages/samples/auth-pages/register"),
        },
        {
            path: "reset",
            name: "reset",
            component: () =>
            import ("@/pages/samples/auth-pages/reset-password"),
        },
        {
            path: "forget-password",
            name: "forget-password",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password"),
        },
        {
            path: "forget-password-confirm",
            name: "forget-password-confirm",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password-confirm"),
        },
        ],
    },
    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            name: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        },
        {
            path: "error-500",
            name: "error-500",
            component: () =>
            import ("@/pages/samples/error-pages/error-500"),
        },
        ],
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },
        // {
        //     path: "/gestion-stock",
        //     component: layoutStock,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/module-stock/stocks/index"),
        //     }, ],
        // },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-GRH/grh/detail"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/grh/create"),
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-patient/patients/index"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-patient/patients/update"),
            props: true,
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-patient/patients/detail"),
            props: true,
        }, ],
    },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "create",
        //         name: "create",
        //         component: () =>
        //             import ("@/pages/priseencharge/create"),
        //     }, ],
        // },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/priseencharge/index"),
        //     }, ],
        // },


    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/permissions",
            name: "role-permissions",
            component: () =>
            import ("@/pages/user/permissions/index"),
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "encaissement",
            name: "encaissement-create",
            component: () =>
            import ("@/pages/tresorerie/encaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement",
            name: "decaissement-create",
            component: () =>
            import ("@/pages/tresorerie/decaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-encaissement",
            name: "list-encaissement",
            component: () =>
            import ("@/pages/tresorerie/encaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-decaissement",
            name: "list-decaissement",
            component: () =>
            import ("@/pages/tresorerie/decaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement/update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/decaissement/update"),
            props: true,
        }, ],
    },
    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "indigence-patient-create",
            name: "indigence-patient-create",
            component: () =>
            import ("@/pages/indigence-patient/create"),
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "list-indigence-patient",
            name: "list-indigence-patient",
            component: () =>
            import ("@/pages/indigence-patient/index"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "create",
            name: "indigence",
            component: () =>
            import ("@/pages/indigence/create"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "list",
            name: "list-indigence",
            component: () =>
            import ("@/pages/indigence/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport",
            name: "rapport",
            component: () =>
            import ("@/pages/rapport/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/caissier",
            name: "rapport-caissier",
            component: () =>
            import ("@/pages/rapport/caissier"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/hospital",
            name: "rapports-hospital",
            component: () =>
            import ("@/pages/rapport-general/hospital"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/geospatial",
            name: "rapports-geospatial",
            component: () =>
            import ("@/pages/rapport-general/geospatial"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/terminal",
            name: "rapport-terminal",
            component: () =>
            import ("@/pages/rapport/terminal"),
        }, ],
    },

    {
        path: "/compagnie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/compagnie/update"),
            props: true,
        }, ],
    },

    {
        path: "/assurance",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/assurance/update"),
            props: true,
        }, ],
    },


    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/actes-medicaux/update"),
            props: true,
        }, ],
    },


    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/role/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence-patient/update"),
            props: true,
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/encaissement/update"),
            props: true,
        }, ],
    },

    {
        path: "*",
        redirect: "/error-404",
        component: {
            render(c) {
                return c("router-view");
            },

            children: [{
                path: "error-404",
                component: () =>
                import ("@/pages/samples/error-pages/error-404"),
            }, ],
        },
    },

        /* ***** Movment routes **/
    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/movment/index"),
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "all",
            name: "all",
            component: () =>
            import ("@/pages/movment/all"),
        }, ],
    },


    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "treatement/:movment_id",
            name: "treatement",
            component: () =>
            import ("@/pages/movment/treatment"),
            props: true,
        }, ],
    },
    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "show/:movment_id",
            name: "show",
            component: () =>
            import ("@/pages/movment/show"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "out/:movment_id",
            name: "out",
            component: () =>
            import ("@/pages/movment/out"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "finish/:movment_id",
            name: "finish",
            component: () =>
            import ("@/pages/movment/finish"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "switcher/:movment_id",
            name: "switcher",
            component: () =>
            import ("@/pages/movment/switcher"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "history/:patients_id",
            name: "history",
            component: () =>
            import ("@/pages/movment/history"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/movment/create"),
        }, ],

    },


    {
        path: "/dashboard",
        component: layout,
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
            import ("@/pages/dashboard"),
        }, ],
    },
    {
        path: "/basic-ui",
        component: layout,
        children: [{
            path: "buttons",
            name: "buttons",
            component: () =>
            import ("@/pages/basic-ui/buttons"),
        },
        {
            path: "dropdowns",
            name: "dropdowns",
            component: () =>
            import ("@/pages/basic-ui/dropdowns"),
        },
        {
            path: "typography",
            name: "typography",
            component: () =>
            import ("@/pages/basic-ui/typography"),
        },
        ],
    },
    {
        path: "/charts",
        component: layout,
        children: [{
            path: "chartjs",
            name: "chartjs",
            component: () =>
            import ("@/pages/charts/chartjs"),
        }, ],
    },
    {
        path: "/tables",
        component: layout,
        children: [{
            path: "basic-tables",
            name: "basic-tables",
            component: () =>
            import ("@/pages/tables/basic-tables"),
        }, ],
    },
    {
        path: "/auth-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "login",
            name: "login",
            component: () =>
            import ("@/pages/samples/auth-pages/login"),
        },
        {
            path: "register",
            name: "register",
            component: () =>
            import ("@/pages/samples/auth-pages/register"),
        },
        {
            path: "reset",
            name: "reset",
            component: () =>
            import ("@/pages/samples/auth-pages/reset-password"),
        },
        {
            path: "forget-password",
            name: "forget-password",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password"),
        },
        {
            path: "forget-password-confirm",
            name: "forget-password-confirm",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password-confirm"),
        },
        ],
    },
    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            name: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        },
        {
            path: "error-500",
            name: "error-500",
            component: () =>
            import ("@/pages/samples/error-pages/error-500"),
        },
        ],
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },
        // {
        //     path: "/gestion-stock",
        //     component: layoutStock,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/module-stock/stocks/index"),
        //     }, ],
        // },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-GRH/grh/detail"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/grh/create"),
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-patient/patients/index"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-patient/patients/update"),
            props: true,
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-patient/patients/detail"),
            props: true,
        }, ],
    },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "create",
        //         name: "create",
        //         component: () =>
        //             import ("@/pages/priseencharge/create"),
        //     }, ],
        // },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/priseencharge/index"),
        //     }, ],
        // },


    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/permissions",
            name: "role-permissions",
            component: () =>
            import ("@/pages/user/permissions/index"),
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "encaissement",
            name: "encaissement-create",
            component: () =>
            import ("@/pages/tresorerie/encaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement",
            name: "decaissement-create",
            component: () =>
            import ("@/pages/tresorerie/decaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-encaissement",
            name: "list-encaissement",
            component: () =>
            import ("@/pages/tresorerie/encaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-decaissement",
            name: "list-decaissement",
            component: () =>
            import ("@/pages/tresorerie/decaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement/update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/decaissement/update"),
            props: true,
        }, ],
    },
    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "indigence-patient-create",
            name: "indigence-patient-create",
            component: () =>
            import ("@/pages/indigence-patient/create"),
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "list-indigence-patient",
            name: "list-indigence-patient",
            component: () =>
            import ("@/pages/indigence-patient/index"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "create",
            name: "indigence",
            component: () =>
            import ("@/pages/indigence/create"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "list",
            name: "list-indigence",
            component: () =>
            import ("@/pages/indigence/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport",
            name: "rapport",
            component: () =>
            import ("@/pages/rapport/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/caissier",
            name: "rapport-caissier",
            component: () =>
            import ("@/pages/rapport/caissier"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/hospital",
            name: "rapports-hospital",
            component: () =>
            import ("@/pages/rapport-general/hospital"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/geospatial",
            name: "rapports-geospatial",
            component: () =>
            import ("@/pages/rapport-general/geospatial"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/terminal",
            name: "rapport-terminal",
            component: () =>
            import ("@/pages/rapport/terminal"),
        }, ],
    },

    {
        path: "/compagnie",
        component: layoutPatient,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/compagnie/update"),
            props: true,
        }, ],
    },

    {
        path: "/assurance",
        component: layoutPatient,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/assurance/update"),
            props: true,
        }, ],
    },

    {
        path: "/actes-medicaux",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/actes-medicaux/update"),
            props: true,
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/role/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence-patient/update"),
            props: true,
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/encaissement/update"),
            props: true,
        }, ],
    },

    {
        path: "*",
        redirect: "/error-404",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        }, ],
    },

        /* ***** Movment routes **/
    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/movment/index"),
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "all",
            name: "all",
            component: () =>
            import ("@/pages/movment/all"),
        }, ],
    },


    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "treatement/:movment_id",
            name: "treatement",
            component: () =>
            import ("@/pages/movment/treatment"),
            props: true,
        }, ],
    },
    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "show/:movment_id",
            name: "show",
            component: () =>
            import ("@/pages/movment/show"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "out/:movment_id",
            name: "out",
            component: () =>
            import ("@/pages/movment/out"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "history/:patients_id",
            name: "history",
            component: () =>
            import ("@/pages/movment/history"),
            props: true,
        }, ],
    },

    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/movment/create"),
        }, ],
    },



    {
        path: "/movments",
        component: mvtlayout,
        children: [{
            path: "folders",
            name: "folders",
            component: () =>
            import ("@/pages/movment/files/index"),
        }, ],
    },

    {
        path: "/dashboard",
        component: layout,
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () =>
            import ("@/pages/dashboard"),
        }, ],
    },
    {
        path: "/basic-ui",
        component: layout,
        children: [{
            path: "buttons",
            name: "buttons",
            component: () =>
            import ("@/pages/basic-ui/buttons"),
        },
        {
            path: "dropdowns",
            name: "dropdowns",
            component: () =>
            import ("@/pages/basic-ui/dropdowns"),
        },
        {
            path: "typography",
            name: "typography",
            component: () =>
            import ("@/pages/basic-ui/typography"),
        },
        ],
    },
    {
        path: "/charts",
        component: layout,
        children: [{
            path: "chartjs",
            name: "chartjs",
            component: () =>
            import ("@/pages/charts/chartjs"),
        }, ],
    },
    {
        path: "/tables",
        component: layout,
        children: [{
            path: "basic-tables",
            name: "basic-tables",
            component: () =>
            import ("@/pages/tables/basic-tables"),
        }, ],
    },
    {
        path: "/auth-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "login",
            name: "login",
            component: () =>
            import ("@/pages/samples/auth-pages/login"),
        },
        {
            path: "register",
            name: "register",
            component: () =>
            import ("@/pages/samples/auth-pages/register"),
        },
        {
            path: "reset",
            name: "reset",
            component: () =>
            import ("@/pages/samples/auth-pages/reset-password"),
        },
        {
            path: "forget-password",
            name: "forget-password",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password"),
        },
        {
            path: "forget-password-confirm",
            name: "forget-password-confirm",
            component: () =>
            import ("@/pages/samples/auth-pages/forget-password-confirm"),
        },
        ],
    },
    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            name: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        },
        {
            path: "error-500",
            name: "error-500",
            component: () =>
            import ("@/pages/samples/error-pages/error-500"),
        },
        ],
    },
    {
        path: "/icons",
        component: layout,
        children: [{
            path: "mdi-icons",
            name: "mdi-icons",
            component: () =>
            import ("@/pages/icons/mdi-icons"),
        }, ],
    },
        // {
        //     path: "/gestion-stock",
        //     component: layoutStock,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/module-stock/stocks/index"),
        //     }, ],
        // },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/grh/index"),
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/grh/update"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-GRH/grh/detail"),
            props: true,
        }, ],
    },
    {
        path: "/grh",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/grh/create"),
        }, ],
    },

    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-GRH/contrat/index"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-GRH/contrat/create"),
        }, ],
    },
    {
        path: "/contrat",
        component: layoutGRH,
        children: [{
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-GRH/contrat/update"),
            props: true,
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "list",
            component: () =>
            import ("@/pages/module-patient/patients/index"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "create",
            component: () =>
            import ("@/pages/module-patient/patients/create"),
        }, ],
    },

    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "update/:uuid",
            name: "update",
            component: () =>
            import ("@/pages/module-patient/patients/update"),
            props: true,
        }, ],
    },
    {
        path: "/patients",
        component: layoutPatient,
        children: [{
                // path: "update/:id(\\d+)",
            path: "detail/:uuid",
            name: "detail",
            component: () =>
            import ("@/pages/module-patient/patients/detail"),
            props: true,
        }, ],
    },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "create",
        //         name: "create",
        //         component: () =>
        //             import ("@/pages/priseencharge/create"),
        //     }, ],
        // },

        // {
        //     path: "/priseencharge",
        //     component: layout,
        //     children: [{
        //         path: "list",
        //         name: "list",
        //         component: () =>
        //             import ("@/pages/priseencharge/index"),
        //     }, ],
        // },

    {
        path: "/",
        component: layout2,
        children: [{
            path: "",
            name: "home",
            component: () =>
            import ("@/pages/dashboard/home"),
        }, ],
    },

        // {
        //   path: "/home",
        //   name: "home",
        //   component: () => import("@/pages/dashboard/home"),
        // },
        // {
        //   path: "/accueil",
        //   component: layout3,
        //   children: [
        //     {
        //       path: "/accueil",
        //       name: "accueil",
        //       component: () => import("@/pages/accueil/accueil"),
        //     },
        //   ],
        // },

        // {
        //     path: "/accueil",
        //     component: accueil,
        // },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "create",
            name: "create-payement",
            component: () =>
            import ("@/pages/module-payment/payment/create"),
        }, ],
    },


    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "rapport",
            name: "rapport-payement",
            component: () =>
            import ("@/pages/module-payment/payment/rapport"),
        }, ],
    },


    {
        path: "/payement",
        component: layoutConfiguration,
        children: [{
            path: "configuration",
            name: "configuration-payement",
            component: () =>
            import ("@/pages/module-payment/payment/configuration"),
        }, ],
    },

    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "dashboard",
            name: "dashboard-payement",
            component: () =>
            import ("@/pages/module-payment/payment/dashboard"),
        }, ],
    },


    {
        path: "/payement",
        component: layoutPayment,
        children: [{
            path: "list",
            name: "list-payement",
            component: () =>
            import ("@/pages/module-payment/payment/index"),
        }, ],
    },

        // {
        //   path: "/facture/details/:id(\\d+)",
        //   name: "FactureDetails",
        //   component: () => import("@/pages/paiement/details-facture"),
        //   props: true,
        // },

    {
        path: "/facture/details/:reference",
        name: "FactureDetails",
        component: () =>
        import ("@/pages/module-payment/payment/details-facture"),
        props: true,
    },

    {
        path: "/eph",
        component: layout,
        children: [{
            path: "create",
            name: "eph-create",
            component: () =>
            import ("@/pages/centre-sante/create"),
        }, ],
    },

    {
        path: "/eph",
        component: layout,
        children: [{
            path: "list",
            name: "eph-list",
            component: () =>
            import ("@/pages/centre-sante/index"),
        }, ],
    },

    {
        path: "/eph",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "eph-update",
            component: () =>
            import ("@/pages/centre-sante/update"),
            props: true,
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "create",
            name: "affectterminal-create",
            component: () =>
            import ("@/pages/affectterminal/create"),
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "list",
            name: "affectterminal-list",
            component: () =>
            import ("@/pages/affectterminal/index"),
        }, ],
    },

    {
        path: "/affectterminal",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "affectterminal-update",
            component: () =>
            import ("@/pages/affectterminal/update"),
            props: true,
        }, ],
    },
    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "create",
            name: "terminal-create",
            component: () =>
            import ("@/pages/terminal/create"),
        }, ],
    },

    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "list",
            name: "terminal-list",
            component: () =>
            import ("@/pages/terminal/index"),
        }, ],
    },

    {
        path: "/terminal",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "terminal-update",
            component: () =>
            import ("@/pages/terminal/update"),
            props: true,
        }, ],
    },
        // {
        //   path: "/profile",
        //   component: layout,
        //   children: [
        //     {
        //       path: "/:id",
        //       name: "profile-update",
        //       component: () => import("@/pages/profile/profileedit"),
        //       // props: true,
        //     },
        //   ],
        // },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "profileedit/:id",
            name: "profile-update",
            component: () =>
            import ("@/pages/profile/profileedit"),
            props: true,
        }, ],
    },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "create",
            name: "profile-create",
            component: () =>
            import ("@/pages/profile/profilepasswordedit"),
        }, ],
    },

    {
        path: "/profile",
        component: layout,
        children: [{
            path: "list",
            name: "profile-list",
            component: () =>
            import ("@/pages/profile/profileshow"),
        }, ],
    },

        // {
        //   path: "/profile",
        //   component: layout,
        //   children: [
        //     {
        //       path: "update/:id(\\d+)",
        //       name: "profile-update",
        //       component: () => import("@/pages/profile/update"),
        //       props: true,
        //     },
        //   ],
        // },
    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "create",
            name: "ephe-create",
            component: () =>
            import ("@/pages/typecentre/create"),
        }, ],
    },

    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "list",
            name: "ephe-list",
            component: () =>
            import ("@/pages/typecentre/index"),
        }, ],
    },

    {
        path: "/ephe",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "ephe-update",
            component: () =>
            import ("@/pages/typecentre/update"),
            props: true,
        }, ],
    },
    {
        path: "/actes-medicaux",
        component: layout,
        children: [{
            path: "create",
            name: "actes-create",
            component: () =>
            import ("@/pages/actes-medicaux/create"),
        }, ],
    },


    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "list",
            name: "actes-list",
            component: () =>
            import ("@/pages/actes-medicaux/index"),
        }, ],
    },

    {
        path: "/actes-medicaux",
        component: serviceLayout,
        children: [{
            path: "create-list/:services_id(\\d+)",
            name: "create-list",
            component: () =>
            import ("@/pages/actes-medicaux/create"),
            props: true,
        }, ],
    },




    {
        path: "/assurance",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "assurance-create",
            component: () =>
            import ("@/pages/assurance/create"),
        }, ],
    },
    {
        path: "/assurance",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "assurance-list",
            component: () =>
            import ("@/pages/assurance/index"),
        }, ],
    },
    {
        path: "/compagnie",
        component: layoutPatient,
        children: [{
            path: "create",
            name: "compagnie-create",
            component: () =>
            import ("@/pages/compagnie/create"),
        }, ],
    },
    {
        path: "/compagnie",
        component: layoutPatient,
        children: [{
            path: "list",
            name: "compagnie-list",
            component: () =>
            import ("@/pages/compagnie/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "create",
            name: "users-create",
            component: () =>
            import ("@/pages/user/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "list",
            name: "users-list",
            component: () =>
            import ("@/pages/user/index"),
        }, ],
    },

    {
        path: "/priseencharge",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/priseencharge/update"),
            props: true,
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "create",
            name: "users-create",
            component: () =>
            import ("@/pages/user/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "list",
            name: "users-list",
            component: () =>
            import ("@/pages/user/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/update"),
            props: true,
        }, ],
    },
    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/create",
            name: "role-create",
            component: () =>
            import ("@/pages/user/role/create"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/list",
            name: "role-list",
            component: () =>
            import ("@/pages/user/role/index"),
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/permissions",
            name: "role-permissions",
            component: () =>
            import ("@/pages/user/permissions/index"),
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "encaissement",
            name: "encaissement-create",
            component: () =>
            import ("@/pages/tresorerie/encaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement",
            name: "decaissement-create",
            component: () =>
            import ("@/pages/tresorerie/decaissement/create"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-encaissement",
            name: "list-encaissement",
            component: () =>
            import ("@/pages/tresorerie/encaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "list-decaissement",
            name: "list-decaissement",
            component: () =>
            import ("@/pages/tresorerie/decaissement/index"),
        }, ],
    },
    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "decaissement/update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/decaissement/update"),
            props: true,
        }, ],
    },
    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "indigence-patient-create",
            name: "indigence-patient-create",
            component: () =>
            import ("@/pages/indigence-patient/create"),
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "list-indigence-patient",
            name: "list-indigence-patient",
            component: () =>
            import ("@/pages/indigence-patient/index"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "create",
            name: "indigence",
            component: () =>
            import ("@/pages/indigence/create"),
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "list",
            name: "list-indigence",
            component: () =>
            import ("@/pages/indigence/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport",
            name: "rapport",
            component: () =>
            import ("@/pages/rapport/index"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/caissier",
            name: "rapport-caissier",
            component: () =>
            import ("@/pages/rapport/caissier"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/hospital",
            name: "rapports-hospital",
            component: () =>
            import ("@/pages/rapport-general/hospital"),
        }, ],
    },
    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport-general/geospatial",
            name: "rapports-geospatial",
            component: () =>
            import ("@/pages/rapport-general/geospatial"),
        }, ],
    },

    {
        path: "/rapport",
        component: layout,
        children: [{
            path: "/rapport/terminal",
            name: "rapport-terminal",
            component: () =>
            import ("@/pages/rapport/terminal"),
        }, ],
    },

    {
        path: "/compagnie",
        component: layoutPatient,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/compagnie/update"),
            props: true,
        }, ],
    },

    {
        path: "/assurance",
        component: layoutPatient,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/assurance/update"),
            props: true,
        }, ],
    },

    {
        path: "/actes-medicaux",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/actes-medicaux/update"),
            props: true,
        }, ],
    },

    {
        path: "/user",
        component: layout,
        children: [{
            path: "role/update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/user/role/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence/update"),
            props: true,
        }, ],
    },

    {
        path: "/indigence-patient",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "update",
            component: () =>
            import ("@/pages/indigence-patient/update"),
            props: true,
        }, ],
    },

    {
        path: "/tresorerie",
        component: layout,
        children: [{
            path: "update/:id(\\d+)",
            name: "tresorerie-update",
            component: () =>
            import ("@/pages/tresorerie/encaissement/update"),
            props: true,
        }, ],
    },

    {
        path: "*",
        redirect: "/error-404",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [{
            path: "error-404",
            component: () =>
            import ("@/pages/samples/error-pages/error-404"),
        }, ],
    },
    ],
});
router.beforeEach((to, from, next) => {
    const publicPages = [
        "/",
        "/auth-pages/login",
        "/auth-pages/forget-password",
        "/auth-pages/reset",
        ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("user");

    if (authRequired && !loggedIn) {
        next("/auth-pages/login");
    } else {
        next();
    }
});

export default router;
