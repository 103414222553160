import Axios from "./caller.services";
import { reactive } from "vue";

const getAllUsers = () => {
  return Axios.get("/login");
};

const getUser = (uuid) => {
  return Axios.get("/login/" + uuid);
};

let getUserr = (user) => {
  return Axios.get("/login/" + user.uuid, user);
};

const addUser = (user) => {
  return Axios.post("/login", user);
};

const updateUser = (user) => {
  return Axios.put("/login/" + user.id, user);
};

const deleteUser = (userId) => {
  return Axios.delete(`/login/${userId}`);
};

const login = (credentials) => {
  return Axios.post("/login", credentials);
};

const saveToken = (token) => {
  localStorage.setItem("token", token);
};

const usercurrent = () => {
    return Axios.get("/auth/user_current");
};

const getToken = () => {
  return localStorage.getItem("access_token");
};

const resetPassword = (credentials) => {
  return Axios.post("/auth/reset-password", credentials);
};

const updateProfilPas = (data) => {
  return Axios.post("/users/changepassword", data);
};
const updateProfil = (data) => {
  return Axios.post("/users/changeprofile", data);
};

const requestPassword = (credentials) => {
  return Axios.post("/auth/request-password", credentials);
};

// const getAllCaissiers = () => {
//   return Axios.get("/caissiers");
// };



const state = reactive({
  isLoggedIn: false,
});

export function isLogged() {
  return state.isLoggedIn;
}

export function setLoggedIn(value) {
  state.isLoggedIn = value;
}


// const logout = () => {
//     var reponse = null;
//     Axios.post("/logout")
//       .then((res) => {
//         console.log(res);
//         localStorage.clear();
//         window.location.replace("/auth-pages/login");
//       })
//       .catch((err) => alert("Vous ne pouvez pas vous déconnecter maintenant"));
//   };
// const logout = () => {
//     Axios.post("/logout")
//       .then((res) => {
//         console.log(res);
//         localStorage.clear();
//         window.location.replace("/auth-pages/login");
//       })
//       .catch((err) => alert("Vous ne pouvez pas vous déconnecter maintenant"));
// };

const logout = () => {
    return Axios.post("/logout");
  }
  


export const LoginService = {
  getAllUsers,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  getUserr,
  login,
  usercurrent,
  getToken,
  saveToken,
  isLogged,
  logout,
  setLoggedIn,
  resetPassword,
  requestPassword,
  updateProfilPas,
  updateProfil,
//   getAllCaissiers,
};
