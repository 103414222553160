/* Import des modules nécessaires */
import Axios from "./caller.services";

let getAllInsurances = () => {
  return Axios.get("/insurances");
};

let getInsurance = (uuid) => {
  return Axios.get("/insurances/" + uuid);
};

// let addPack = (pack) => {
//   return Axios.post("/packs", pack);
// };

// let getPackByAssurance = (assuranceId) => {
//   return Axios.get("/pack?assuranceId=" + assuranceId);
// };
// let updatePack = (pack) => {
//   return Axios.put("/packs/" + pack.id, pack);
// };

// let deletePack = (id) => {
//   return Axios.delete("/packs/" + id);
// };

export const insuranceService = {
  getAllInsurances,
  getInsurance,
  // getPack,
  // addPack,
  // getPackByAssurance,
  // updatePack,
  // deletePack,
};
