<template>
  <div>
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="footer-info">
                <h3>MedKey</h3>
                <p>
                  Hôpital de zone, BENIN.<br /><br />
                  <strong>Phone:</strong> +229 94 58 3999<br />
                  <!-- <strong>Email:</strong> akasi-group@akasigroup.com<br />  -->
                 <strong>Email:</strong> <a href="mailto:akasi-group@akasigroup.com">akasi-group@akasigroup.com</a>

                </p>
                <div class="social-links mt-3">
                  <a href="https://twitter.com/Presidencebenin" target="_blank"  class="twitter"><i class="bx bxl-twitter"></i></a>
                  <a href="https://www.facebook.com/msantebenin/"  target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>

                  <!--<a href="#" class="google-plus"
                    ><i class="bx bxl-skype"></i
                  ></a>-->
                  <!--<a href="#" class="instagram"
                    ><i class="bx bxl-instagram"></i
                  ></a>-->

                  <a href="https://www.linkedin.com/company/ministere-de-la-sante-du-benin/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 footer-links">
              <h4>Liens utiles</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#hero">Accueil</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#about">A Propos</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#services">Services</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#module">Nos Solution</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#actor">Acteurs de MedKey</a>
                </li>
              </ul>
            </div>

            <!-- <div class="col-lg-3 col-md-6 footer-links">
              <h4>Nos Services</h4>
              <ul>
                <p>
                
                 Gestion des Patients<br /><br />
                 Carte sanitaire <br />
                </p>
               
                <li>
                  <i class=""></i>
                  <p>Carte sanitaire</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Gestion des actes médicaux</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Paiements et Facturations</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Gestion de la trésorerie</p>
                </li>
              </ul>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
              <h4>Contactez-nous</h4>
              <p><b> Appelez-nous au</b>: </p>
              <p><span>Email</span>:</p>
              
            </div> -->
            <!-- <div class="col-lg-3 col-md-6 footer-links">
              <h4>Nos Services</h4>
              <ul>
                <li><i class="fa-solid fa-baby"></i> Maternité</li>
                <li><i class="fa-solid fa-child"></i> Pédiatrie</li>
                <li><i class="fa-solid fa-camera"></i> Imagerie</li>
                <li><i class="fa-solid fa-microscope"></i> Laboratoire</li>
                <li><i class="fa-solid fa-procedures"></i> Chirurgie</li>
                <li><i class="fa-solid fa-first-aid"></i> Infirmerie</li>
              </ul>
            </div> -->
            <div class="col-lg-4 col-md-6 footer-links">
              <h4>Services</h4>
              <ul>
                <li>
                  <i class="fa-solid fa-baby"></i> <a href="#">Maternité</a>
                </li>
                <li>
                  <i class="fa-solid fa-child"></i> <a href="#">Pédiatrie</a>
                </li>
                <li>
                  <i class="fa-solid fa-camera"></i> <a href="#">Imagerie</a>
                </li>
                <li>
                  <i class="fa-solid fa-microscope"></i>
                  <a href="#">Laboratoire</a>
                </li>
                <li>
                  <i class="fa-solid fa-procedures"></i>
                  <a href="#">Chirurgie</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; Copyright © 2023
          <a href="http://akasigroup.net/" target="_blank"><strong><span>Akasi Group SARL</span></strong></a>
          Tous droits réservés | Politique de confidentialité | Terms et
          Conditions
        </div>
      </div>
    </footer>
    <!-- End Footer -->
  </div>
</template>

<script>
// Déplacez les importations JavaScript ici, à l'intérieur de la section <script>

export default {
  name: "FooterComponent",
  components: {},
};
</script>

<style>
@import "../asset/css/style.css";
</style>