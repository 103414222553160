/**Import des modules néccessaires */
import Axios from "../caller.services";

let getAllCaisse = () => {
  return Axios.get("/cashregister");
};


let addFund = (uuid, amount) => {
  return Axios.put(`/addfund/${uuid}/${amount}`);
 };


let getHistoriqueOpen = () => {
  return Axios.get("/gethistoriqueopen");
};

let getHistoriqueClose = () => {
  return Axios.get("/gethistoriqueclose");
};



let getCashiersCashRegister = (id) => {
  return Axios.get("/getcashierscashregister/" + id);
};

let getUserByID = (id) => {
  return Axios.get("/getuserbyid/" + id);
};

let getSolde = (id) => {
  return Axios.get("/get-solde/" + id);
};

let getCashRegisterCashier = (id) => {
  return Axios.get("/getcashregistercashier/" + id);
};


// let getCentre = (centresanitaire) => {
//   return Axios.get("/centresanitaire/" + centresanitaire.id, centresanitaire);
// };

let addCashRegister = (credentials) => {
  return Axios.post("/cashregister", credentials);
};

let cashRegisterTransfert = (credentials) => {
  return Axios.post("/cashregistertransfert", credentials);
};

let getCashRegisterTransfert = () => {
  return Axios.get("/cashregistertransfert");
};

let getApprover = () => {
  return Axios.get("/getapprover");
};

let affectCashier = (credentials) => {
  return Axios.post("/affectcashier", credentials);
};

let getCashiers = () => {
  return Axios.get("/getcashiers");
};

let openCloseCashRegister = (credentials) => {
  return Axios.post("/openclosecashregister", credentials);
};

// let updateCentre = (centresanitaire) => {
//   return Axios.put("/centresanitaire/" + centresanitaire.id, centresanitaire);
// return Axios.put(`/choosecashregister/${requestBody.cashier_id}/${user_id}`, requestBody);

// };

 let chooseCashRegister = (cashRegisterID, cashierID) => {
  return Axios.put(`/choosecashregister/${cashRegisterID}/${cashierID}`, { cashRegisterID });
 };


// let deleteCentre = (id) => {
//   return Axios.delete("/centresanitaire/" + id);
// };

export const caisseService = {
  getAllCaisse,
  chooseCashRegister,
  getCashRegisterTransfert,
  cashRegisterTransfert,
  getApprover,
  getCashRegisterCashier,
  getHistoriqueOpen,
  getHistoriqueClose,
  getSolde,
  addFund,
  addCashRegister,
  openCloseCashRegister,
  affectCashier,
  getCashiers,
  getCashiersCashRegister,
  getUserByID,
};
