/* Import des modules nécessaires */
import Axios from "./caller.services";

let getAllPacks = () => {
  return Axios.get("/packs");
};

let getPack = (id) => {
  return Axios.get("/packs/" + id);
};

let addPack = (pack) => {
  return Axios.post("/packs", pack);
};

let getPackByAssurance = (assuranceId) => {
  return Axios.get("/pack/" + assuranceId);
};
let updatePack = (pack) => {
  return Axios.put("/packs/" + pack.id, pack);
};

let deletePack = (id) => {
  return Axios.delete("/packs/" + id);
};

export const packService = {
  getAllPacks,
  getPack,
  addPack,
  getPackByAssurance,
  updatePack,
  deletePack,
};
