/**Import des modules néccessaires */
import Axios from "../caller.services";

//get all drugs in the database
let getAllDrugs = () => {
    return Axios.get("/product/drugs");
};

//get all consumables in the database
let getAllConsumables = () => {
    return Axios.get("/product/consumables");
};

//get all notebooks and cards in the database
let getAllNotebooksAndCards = () => {
    return Axios.get("/product/notebooks_and_cards");
};

//get all products in the database
let getAllProductSpecifications = () => {
    return Axios.get("/products");
};

let getProductSpecification = (uuid) => {
    return Axios.get("/products/" + uuid);
};
let getProductLocations = (uuid) => {
    return Axios.get("/product/" + uuid + "/locations");
};

let getProductSpecifications = (product) => {
    return Axios.get("/products/" + product.uuid, product);
};

let addProductSpecification = (data) => {
    return Axios.post("/products", data);
};

let updateProductSpecification = (product) => {
    return Axios.put("/products/" + product.uuid, product);
};

let deleteProductSpecification = (uuid) => {
    return Axios.delete("/products/" + uuid);
};

export const productSpecificationService = {
    getAllProductSpecifications,
    getProductLocations,
    getAllNotebooksAndCards,
    getAllConsumables,
    getAllDrugs,
    getProductSpecification,
    getProductSpecifications,
    addProductSpecification,
    updateProductSpecification,
    deleteProductSpecification,
};