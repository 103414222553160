<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <!--------------------------Start Stock Module Configuration-------------------------->
        <li class="nav-item">
          <router-link class="nav-link" to="/configuration/store/list" style="text-decoration: none">
            <span class="menu-title"> Magasins </span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/configuration/stock/list" style="text-decoration: none">
            <span class="menu-title"> Stocks </span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/configuration/category/list" style="text-decoration: none">
            <span class="menu-title"> Catégories </span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/configuration/supplier/list" style="text-decoration: none">
            <span class="menu-title"> Fournisseurs </span>
          </router-link>
        </li>

        <li class="nav-item">

          <router-link class="nav-link" to="/payement/configuration" style="text-decoration: none">
            <span class="menu-title">Signataires </span>

          </router-link>
        </li>
        <!--------------------------Start Stock Module Configuration-------------------------->
      </ul>
    </nav>
  </section>
</template>

<script>
import { LoginService, setLoggedIn } from "@/_services";

export default {
  name: "sidebarConfiguration",
  data() {
    return {
      // user: JSON.parse(localStorage.getItem("user")),
      // permission: JSON.parse(localStorage.getItem("permission")),
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    logout() {
      LoginService.logout();
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>

<style>
/* .bien{
  filter: brightness(100%);
  } */

.special-style-icon {
  color: white !important;
}

.nav-item,
.nav-link {
  /* font-weight: bold; */
  /* font-size: 18px !important;  */
  font-family: "Montserrat";
}

.ok {
  font-weight: bold !important;
  color: black !important;
}
</style>
