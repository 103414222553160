<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">

        <li class="nav-item" style="border-bottom:1px solid #666">
          <!-- v-if="permission.includes('Voir_module_assurance_patient')" -->
          <!-- v-if="permission.includes('Voir_module_assurance_patient')" -->

          <router-link
            class="nav-link"
            to="/movments/list"
            style="text-decoration: none"
          >
            <span class="menu-title"> Venues en cours </span>
          </router-link>
        </li>


 <li class="nav-item">
          <router-link
            class="nav-link"
            to="/movments/all"
            style="text-decoration: none"
          >
            <span class="menu-title">Toutes les Venues</span>

          </router-link>
        </li>


     <!--   <li class="nav-item" >
          <router-link
            class="nav-link"
            to="#"
            style="text-decoration: none"
          >
            <span class="menu-title">Messages</span>

          </router-link>
        </li>-->

        <li class="nav-item" >
          <router-link
            class="nav-link"
            to="#"
            style="text-decoration: none"
          >
            <span class="menu-title">Rapport des Venues</span>

          </router-link>
        </li>

       <!-- <li class="nav-item" >
          <router-link
            class="nav-link"
            to="#"
            style="text-decoration: none"
          >
            <span class="menu-title">Dossiers Patients</span>

          </router-link>
        </li>-->


        <li class="nav-item ">
           <!-- v-if="permission.includes('Voir_module_rapport_general')" -->
          <!-- v-if="permission.includes('Voir_module_tresorier')"-->
          <span class="nav-link" v-b-toggle="'rapports'">
            <span class="menu-title text-danger font-weight-bold">Aller aux modules </span>
            <i class="menu-arrow"></i>
          </span>

          <b-collapse accordion="sidebar-accordion" id="rapports">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/patients/list"
                  >Patients</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/payement/list"
                  >Facturations & paiements
                </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="#"
                  >Recouvrements & Remboursements
                </router-link>
              </li>

               <li class="nav-item">
                <router-link class="nav-link" to="#"
                  >Services Médicaux
                </router-link>
              </li>


              <li class="nav-item">
                <router-link class="nav-link" to="#"
                  >Hosptalisations
                </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="#"
                  >Assurances
                </router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>

    </nav>
  </section>
</template>

<script>
import { LoginService, setLoggedIn } from "@/_services";

export default {
  name: "sidebar",
  data() {
    return {
      // user: JSON.parse(localStorage.getItem("user")),
      // permission: JSON.parse(localStorage.getItem("permission")),
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    // logout() {
    //   LoginService.logout();
    // },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>

<style>
/* .bien{
  filter: brightness(100%);
  } */

.special-style-icon {
  color: white !important;
}

.nav-item,
.nav-link {
  /* font-weight: bold; */
  /* font-size: 18px !important;  */
  font-family: "Montserrat";
}

.ok {
  font-weight: bold !important;
  color: black !important;
}
</style>
