<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    toggleable="lg" style="background: #0b5d3f">

    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <router-link class="navbar-brand brand-logo" to="/">

        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>

      <router-link style="font-size:0.75em;" class="navbar-brand brand-logo-mini" to="/">
        <router-link class="navbar-brand brand-logo-mini" to="/">
          <img src="@/assets/images/logo.png" alt="logo" style="width: 125px; height: 65px" />
        </router-link>

      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button class="navbar-toggler navbar-toggler align-self-center d-lg-block" type="button" @click="toggleSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>

      <button class="navbar-toggler navbar-toggler-right align-self-center" type="button" @click="toggleMobileSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
      <b class="text-white d-lg-flex align-items-center justify-content-between"
        style="background-color: #0b5d3f; padding: 10px;">CENTRE HOSPITALIER DE LOKOSSA </b>
      <b class="text-white ml-lg-4">{{ user.name }} {{ user.prenom }}</b>
    </div>

    <div>
      <span class="nav-link">
        <button v-on:click="logout" class="btn btn-block pt-3 mx-auto text-center " style="background-color: #eadb9a">
          <span style="color: rgb(12, 1, 1) !important; font-weight: bold">Déconnexion</span>
        </button>
      </span>
    </div>
  </b-navbar>
</template>

<script>
import { LoginService, setLoggedIn } from "@/_services";
export default {
  name: "header",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),

    };
  },

  methods: {
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },

    // logout() {
    //   // Effectuer la déconnexion
    //   LoginService.logout()
    //     .then((res) => {
    //       console.log(res);
    //       localStorage.clear();
    //       window.location.replace("/auth-pages/login");
    //     })
    //     .catch((err) => alert("Vous ne pouvez pas vous déconnecter maintenant"));
    // },
    logout() {
  // Effectuer la déconnexion
  LoginService.logout()
    .then((res) => {
      console.log(res);
      localStorage.clear();
      window.location.replace("/auth-pages/login");
    });
}

  },
};
</script>

<style >
.ml-lg-4 {
  margin-left: 32.5rem !important;
}
</style>
