<template>
  <div>
    <!-- ======= Body ======= -->
    <!-- ======= Hero Section ======= -->
    <!-- ======= Featured Services Section ======= -->
    <section id="featured-services" class="featured-services">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-heartbeat"></i></div>
              <h4 class="title"><a href="">Efficacité et rapidité</a></h4>

              <p class="description accord" style="margin-top: 40px">
                MedKey facilite et accélère les processus associés à la gestion
                des dossiers médicaux et des recettes. Les documents médicaux
                sont numérisés, stockés et accessibles électroniquement de
                manière simple, ce qui réduit les délais de traitement et
                améliore la productivité globale du système de santé.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-pills"></i></div>
              <h4 class="title"><a href="">Réduction des coûts</a></h4>
              <p class="description accord okk">
                MedKey élimine la nécessité de manipuler des documents
                physiques, ce qui entraîne une diminution des coûts liés au
                stockage, à la gestion des archives et à la distribution papier.
                De plus, les processus automatisés permettent de réduire les
                erreurs humaines et les retards, ce qui peut également conduire
                à des économies financières.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-thermometer"></i></div>
              <h4 class="title"><a href="">Sécurité des données</a></h4>
              <p class="description accord">
                MedKey propose des mesures de sécurité renforcées pour les
                données sensibles. Les systèmes intègrent des mécanismes de
                contrôle d'accès, de cryptage et de sauvegardes régulières afin
                de réduire les risques de perte ou de vol de données.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-dna"></i></div>
              <h4 class="title">
                <a href="">Accessibilité et disponibilité</a>
              </h4>
              <p class="description accord">
                Les dossiers médicaux dématérialisés facilitent la consultation
                et le partage entre les divers acteurs de la santé, tels que les
                médecins, les hôpitaux et les compagnies d'assurance. Cette
                démarche favorise une coordination améliorée des soins et réduit
                les redondances ainsi que les erreurs médicales.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Featured Services Section -->
    <section id="cta" class="cta">
      <div class="container">
        <div class="text-center">
          <h3>Besoin d'aide ? Rendez-vous dans nos centres de santé !</h3>
          <p>
            Obtenez les réponses à toutes vos questions médicales, accédez à des
            conseils éclairés et bénéficiez d'une expertise de confiance. Prenez
            le pouvoir sur votre bien-être grâce à notre plateforme !
          </p>
        </div>
      </div>
    </section>
    <!-- End Cta Section -->

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container">
        <div class="section-title">
          <h2>A propos</h2>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <img src="../asset/img/about.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content">
            <h3>MedKey</h3>

            <p style="text-align: justify">
              MedKey est une plateforme de gestion des systeme d'information hospitalier qui vise à dématérialiser la
              gestion des recettes sanitaires et assurer la transparence dans la gestion de tout l'hopital. Son objectif
              est de simplifier et d'optimiser la gestion des
              opérations financières liées aux soins de santé. <br />
              <br />
              Elle permet à l'hôpital de gérer
              électroniquement toutes les transactions financières liées aux
              soins de santé, telles que la facturation, la gestion des
              paiements, la gestion des patients, la gestion de la carte
              sanitaire, la gestion des actes médicaux et la gestion de la
              trésorerie.
            </p>

            <p style="font-weight: bold; color: rgb(7, 92, 78)">
              MedKey représente la solution parfaite pour vos besoins en matière
              de soins de santé !
            </p>
          </div>
        </div>
      </div>
    </section>



    <!-- End About Us Section -->

    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fas fa-user-md"></i>
              <span class="purecounter">3500</span>

              <p class="text-justify">
                <strong>Nombre de patients traités:</strong> Cette statistique donne une idée du volume de patients que
                l'hôpital traite.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fa-solid fa-bed"></i>
              <span data-purecounter-start="0" data-purecounter-end="12" data-purecounter-duration="1"
                class="purecounter">85%</span>
              <p class="text-justify">
                <strong>Taux d'occupation des lits:</strong> Il indique combien de lits de l'hôpital sont occupés à un
                moment donné.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fas fa-flask"></i>
              <span data-purecounter-start="0" data-purecounter-end="77" data-purecounter-duration="1"
                class="purecounter">120</span>
              <p class="text-justify">
                <strong>Nombre de chirurgies:</strong> Il s'agit du nombre de procédures chirurgicales
                réalisées.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fa-solid fa-clock"></i>
              <span data-purecounter-start="0" data-purecounter-end="546" data-purecounter-duration="1"
                class="purecounter">95%</span>
              <p class="text-justify">
                <strong>Taux de satisfaction des patients</strong> Les retours des patients sur leur expérience de soins
                sont importants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Counts Section -->

    <!-- ======= Features Section ======= -->
    <section id="features" class="features">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="icon-box mt-5 mt-lg-0">
              <i class="bx bx-receipt"></i>
              <h4>Accessibilité améliorée</h4>
              <p>
                MedKey offre aux patients une accessibilité aisée aux services
                de caisse sanitaire, quel que soit l'endroit où ils se trouvent,
                tant qu'ils disposent d'une connexion Internet.
              </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-cube-alt"></i>
              <h4>Communication plus rapide</h4>
              <p>
                Les patients peuvent entrer en contact avec la caisse sanitaire
                via notre plateforme en ligne, ce qui permet d'obtenir des
                réponses plus rapides à leurs interrogations et inquiétudes.
              </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-images"></i>
              <h4>Assurance</h4>
              <p>MedKey simplifie les procédures relatives à l'assurance.</p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-shield"></i>
              <h4>Gestion du risque</h4>
              <p>
                L'assurance joue un rôle crucial dans la gestion des risques en
                offrant aux individus et aux entreprises la possibilité de se
                protéger contre divers risques tels que la santé, les biens, la
                responsabilité civile, les catastrophes naturelles, et bien
                d'autres.
              </p>
            </div>
          </div>
          <div class="image col-lg-6 order-1 order-lg-2" id="featuredImage"></div>
        </div>
      </div>
    </section>
    <!-- End Features Section -->

    <!-- module section -->
    <section id="module">
      <h1 class="text-center">
        Nos solutions
        <span class="green-underline"></span>
      </h1>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/patients.png" alt="Gestion des Patients">
              <p class="module-title">Gestion des Patients</p>
              <p>Module de suivi des dossiers des patients, de leur historique médical et de leur information personnelle.
                Facilite la gestion des rendez-vous et des traitements.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/indigence.png" alt="Mouvement Patient">
              <p class="module-title">Mouvement Patient</p>
              <p>Module de suivi des mouvements des patients, des admissions, des transferts et des sorties.</p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/paiements.png" alt="Facturation & Paiements">
              <p class="module-title">Facturation & Paiements</p>
              <p>Module de gestion des factures, des paiements, et de suivi des transactions financières. Permet de gérer
                les paiements des patients et la facturation des services médicaux.</p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/pharmacie.png" alt="Gestion de Caisse">
              <p class="module-title">Pharmacies</p>
              <p>Module de gestion des pharmacies et des médicaments.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/money-flow.png" alt="Gestion de Caisse">
              <p class="module-title">Recouvrements et Remboursements</p>
              <p>Module de suivi des recouvrements et des remboursements des coûts médicaux.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/treasury.png" alt="Gestion de Caisse">
              <p class="module-title">Gestion de Caisse</p>
              <p>Module de suivi des transactions de caisse, de gestion des ventes et des encaissements. Idéal pour les
                activités de point de vente et de commerce.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/package.png" alt="Gestion de Caisse">
              <p class="module-title">Stock/Consommable</p>
              <p>Module de gestion des stocks et des consommables médicaux.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/hopital.png" alt="Gestion de Caisse">
              <p class="module-title">Hospitalisations</p>
              <p>Module de suivi des hospitalisations des patients et de la disponibilité des lits.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/teamwork.png" alt="Gestion de Caisse">
              <p class="module-title">Ressources Humaines</p>
              <p>Module de gestion du personnel, des employés et de la planification des ressources humaines.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/assurancee.png" alt="Gestion de Caisse">
              <p class="module-title">Assurance</p>
              <p>Module de gestion des informations sur les polices d'assurance des patients et des demandes de règlement.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/actsmedicaux.png" alt="Gestion de Caisse">
              <p class="module-title">Services Médicaux</p>
              <p>Module de gestion des services médicaux offerts par le centre médical.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/calendrier.png" alt="Gestion de Caisse">
              <p class="module-title">Rendez-vous</p>
              <p>Module de gestion des rendez-vous pour les patients et les médecins.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/user.png" alt="Gestion de Caisse">
              <p class="module-title">Profil du Centre</p>
              <p>Module de gestion des informations du centre médical, y compris les coordonnées et les détails de
                contact.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/money.png" alt="Gestion de Caisse">
              <p>Petite Caisse</p>
              <p>Module de gestion de la petite caisse pour les dépenses mineures.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/data-report.png" alt="Gestion de Caisse">
              <p class="module-title">Rapports et Statistiques</p>
              <p>Module de génération de rapports et de statistiques pour l'analyse des performances du centre médical.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="module-thumbnail">
              <img src="@/assets/images/parametrage.png" alt="Gestion de Caisse">
              <p class="module-title">Configurations et Paramètres</p>
              <p>Module de configuration des paramètres du système et des préférences du centre médical.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end module section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services services">
      <div class="container">
        <div class="section-title">
          <h2>Services médicaux</h2>
        </div>

        <br>
        <div class="row">
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fa-solid fa-baby"></i>
            </div>

            <h4 class="title"><a href=""> MATERNITE </a></h4>
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-child"></i>
            </div>
            <h4 class="title">
              <a href="">PEDIATRIE</a>
            </h4>
            <!-- <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fa-solid fa-syringe"></i>
            </div>
            <h4 class="title"><a href="">CHIRURGIE</a></h4>
            <!-- <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fa-solid fa-image"></i>
            </div>
            <h4 class="title"><a href="">IMAGERIE</a></h4>
            <!-- <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fa-solid fa-flask"></i>
            </div>
            <h4 class="title"><a href="">LABORATOIRE</a></h4>
            <!-- <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-clinic-medical"></i>
            </div>
            <h4 class="title"><a href="">INFIRMERIE</a></h4>
            <!-- <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p> -->
          </div>
        </div>
      </div>
    </section>


    <!-- End Services Section -->
    <!-- <section id="departements" class="counts">
      <div class="container">
        <div class="section-title">
          <h2>Nos SOLUTIONS</h2>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div class=""> <i class="fa-solid fa-user bien"></i></div>
              <h6 class="purecounter " style="font-weight: bold;">Patients</h6>
           
              <p class="text-justify">
                Notre solution permet une gestion complète des informations
                des patients, incluant leur nom, prénom, âge, profession,
                numéro de téléphone, et bien d'autres.<br />
                <br />

                Le module de gestion des patients permet également de mettre
                en évidence la base de données de tous les patients au sein de
                l'hôpital.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div><i class="fa-solid fa-walking bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Mouvements</h6>
        
             <p class="text-justify">
                MedKey assure une gestion complète et précise
                de toutes les interactions des patients au sein de l'établissement de santé. Il permet de suivre et
                d'enregistrer une <br />
                <br />
                Admissions et sorties, Transferts entre services, Procédures médicales, Prescriptions médicales,
                Rendez-vous et consultations
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div><i class="fa-solid fa-file-invoice-dollar bien"></i></div>
              <h6 class="purecounter " style="font-weight: bold;">Facturation Paiements</h6>

              <p class="text-justify">
                Notre solution propose une gestion complète des processus de facturation et de paiements, facilitant ainsi
                la gestion des transactions financières liées aux soins. <br /><br />

                Le module de facturation permet de créer des factures détaillées pour les services médicaux rendus aux
                patients.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-hospital bien"></i></div>
              <h6 class="purecounter " style="font-weight: bold;">Pharmacie</h6>

              <p class="text-justify">
                Le module de gestion de la pharmacie de notre solution offre une gestion complète des activités
                pharmaceutiques au sein de l'établissement de santé. <br /> <br />

                Il englobe les aspects suivants: inventaire des médicaments, gestion des commandes de médicaments
                ,distribution de médicaments.
              </p>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-hand-holding-usd bien"></i>
              </div>
              <h6 class="purecounter " style="font-weight: bold;">Recouvrement et Remboursement</h6>

              <p class="text-justify">
                Notre module de recouvrement permet une gestion efficace des paiements et de la récupération
                des coûts. Il assure un suivi précis des paiements des patients. <br /> <br />

                Le module de remboursement facilite le traitement des demandes de remboursement des patients et la gestion
                des remboursements de la part des compagnies d'assurance.
                santé.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-cash-register bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Caisse</h6>
              <br>
              <p class="text-justify">
                Notre solution offre un module de gestion de la caisse qui assure une gestion complète et
                transparente des transactions financières au sein de l'établissement de santé. <br /> <br />

                Ce module permet de suivre les flux de trésorerie, de consigner les paiements des patients, de gérer les
                factures, et de garantir une traçabilité complète des transactions financières.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div><i class="fa-solid fa-box bien"></i>
              </div>
              <h6 class="purecounter " style="font-weight: bold;">Stocks/Consommable</h6>
              <br>
              <p class="text-justify">
                Notre solution comprend également un module dédié à la gestion des stocks et des
                consommables, garantissant un suivi efficace de l'inventaire des produits médicaux, des équipements.
                <br /> <br />

                Le module de gestion des stocks permet de maintenir un registre complet des articles médicaux disponibles,
                de surveiller les niveaux de stock, de gérer les réapprovisionnements.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div><i class="fa-solid fa-hospital bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Hospitalisations</h6>
              <br>
              <p class="text-justify">
                Le module d'hospitalisations de notre solution offre une gestion complète des séjours
                hospitaliers des patients. Il permet de suivre et d'enregistrer diverses informations essentielles.<br />
                <br />

                De plus, il facilite la coordination des ressources hospitalières en permettant la planification des
                lits,la gestion des transferts entre les unités de soins, et la gestion des procédures administratives.
              </p>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-users bien"></i>
              </div>
              <h6 class="purecounter " style="font-weight: bold;">Ressources humaines</h6>

              <p class="text-justify">
                Le module de gestion des ressources humaines (RH) de notre solution offre une gestion complète des
                informations relatives au personnel de l'établissement de santé. <br /><br />

                Il permet de gérer une variété de données
                essentielles pour la gestion des ressources humaines comme dossier du personnel, horaire et présence,
                gestion des salaires et des avantages.

              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-shield-alt bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Assurances</h6>

              <p class="text-justify">
                Le module d'assurances de notre solution offre une gestion complète des informations liées aux assurances
                des patients. Il permet de consigner et de gérer les détails importants. <br /> <br />
                Notre solution facilite également le suivi des paiements, des réclamations et des documents liés à
                l'assurance. En centralisant ces informations, elle améliore la gestion des couvertures.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-medkit bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Services médicaux</h6>

              <p class="text-justify">
                Notre solution propose une gestion complète des services médicaux offerts au sein de l'établissement de
                santé. Cela inclut la documentation de chaque service, sa description, son personnel dédié. <br /><br />
                Le module de gestion des services médicaux permet
                également de maintenir une base de données complète de tous les services médicaux disponibles à l'hôpital,
                garantissant une vue.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-calendar-check "></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Rendez-vous</h6>

              <p class="text-justify">
                Le module de gestion des rendez-vous de notre solution facilite la gestion complète des rendez-vous pour
                les patients. Il permet de planifier, suivre et gérer efficacement les rendez-vous médicaux. <br /> <br />

                Il offre la possibilité de planifier des rendez-vous médicaux pour les patients,améliorant
                ainsi la coordination des soins de santé au sein de l'établissement.
              </p>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-hospital bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Profil du centre</h6>
              <br>
              <p class="text-justify">
                Le module Profil du centre de notre solution est conçu pour centraliser et gérer de manière complète les
                informations concernant l'établissement de santé. <br /> <br />

                Il inclut les détails essentiels du centre, tels que son
                nom, sa localisation, ses coordonnées, sa spécialité, son personnel, et bien d'autres informations
                pertinentes.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fa-solid fa-cash-register bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Petite caisse</h6>
              <br>
              <p class="text-justify">
                Le module de gestion de la Petite Caisse de notre solution offre une gestion complète des transactions
                financières de petite envergure. Il permet d'enregistrer, <br /> <br />
                Et de suivre les dépenses et les recettes, offrant ainsi une gestion financière précise pour les petites
                transactions au sein de l'établissement de
                santé.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div> <i class="fas fa-chart-bar bien"></i>

              </div>
              <h6 class="purecounter " style="font-weight: bold;">Rapports et Statistiques</h6>

              <p class="text-justify">
                Notre solution inclut un module dédié à la génération de rapports et de statistiques. Ce module permet de
                d'analyser diverses données liées à l'activité de l'Hôpital. <br /> <br />

                La fonctionnalité clé du module Rapports et Statistiques comprennent la création de rapports
                personnalisés.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch bien">
            <div class="count-box">
              <div><i class="fa-solid fa-cog"></i>


              </div>
              <h6 class="purecounter " style="font-weight: bold;">Configurations et Paramétrage</h6>

              <p class="text-justify">
                Notre solution offre une flexibilité exceptionnelle en matière de configurations. Vous pouvez
                personnaliser divers paramètres selon les besoins de votre établissement de santé. Cela inclut la
                configuration de préférences système, de modèles de documents, de règles de sécurité, de notifications, et
                bien plus encore. Vous avez le contrôle total pour adapter la solution à vos exigences spécifiques.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- ======= Departments Section ======= -->
    <!-- <section id="departments" class="departments">
      <div class="container">
        <div class="section-title">
          <h2>Nos SOLUTIONS</h2>
        </div>

        <div class="row">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <ul class="nav nav-tabs flex-column">
              <li class="nav-item">
                <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                  <h4>GESTION DES PATIENTS</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                  <h4>FACTURATION ET PAIEMENTS</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                  <h4>ACTES MEDICAUX</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                  <h4>CARTE SANITAIRE</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
                  <h4>GESTION DE LA TRESORERIE</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-6">
                  <h4>PHARMACIE</h4>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-8">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <h3>Gestion des Patients</h3>
                <img src="../images/aide1.jpg" alt="" class="img-fluid" />
                <p class="text-justify">
                  Notre solution permet une gestion complète des informations
                  des patients, incluant leur nom, prénom, âge, profession,
                  numéro de téléphone, et bien d'autres.<br />
                  <br />
                  <br />
                  Le module de gestion des patients permet également de mettre
                  en évidence la base de données de tous les patients au sein de
                  l'hôpital.
                </p>
              </div>
              <div class="tab-pane" id="tab-2">
                <h3>Facturation et paiements</h3>
                <img src="../images/payement.jpg" alt="" class="img-fluid" />
                <p class="text-justify">
                  Notre solution simplifie la gestion des processus de facturation et de paiement.

                  Elle vous permet de gérer efficacement les transactions financières, incluant la création des factures,
                  le suivi des paiements et la gestion des relevés. <br />
                  <br />
                  Ce module centralise l'ensemble des opérations financières au sein de votre établissement hospitalier,
                  offrant une vue complète et organisée de toutes les transactions liées aux soins de santé.
                </p>
              </div>
              <div class="tab-pane" id="tab-3">
                <h3>Gestion des actes médicaux</h3>
                <img src="../images/actes.jpg" alt="" class="img-fluid" />
                <p class="text-justify">
                  Notre solution garantit la gestion de divers types de services
                  médicaux facturés au niveau de la caisse <br />
                  <br />
                  Ce module facilite la classification des actes médicaux en
                  fonction des demandes des clients, dans le but d'améliorer la
                  qualité des services de santé publics.
                </p>
              </div>
              <div class="tab-pane" id="tab-4">
                <h3>Carte sanitaire</h3>
                <img src="../images/carte.jpg" alt="" class="img-fluid" />
                <p>
                  MedKey offre une gestion complète de la carte sanitaire, incluant des informations telles
                  que les antécédents médicaux, les traitements en cours, les vaccinations, et bien d'autres.
                  <br />
                  <br />
                  Le module de gestion de la carte sanitaire permet également de consolider les informations
                  médicales de chaque patient au sein de l'hôpital de Lokossa.
                </p>
              </div>
              <div class="tab-pane" id="tab-5">
                <h3>Gestion de la trésorerie</h3>
                <img src="../images/tresor.jpg" alt="" class="img-fluid" />
                <p>
                  Le système prend en charge les flux financiers d'entrée et de
                  sortie, garantissant une gestion optimale de la trésorerie. Il
                  offre une gestion sécurisée ainsi qu'un suivi des
                  encaissements et des décaissements. <br />
                  <br />Ce module permettra de surveiller en temps réel
                  l'évolution des finances, d'anticiper la gestion des risques
                  financiers et de prendre les mesures nécessaires pour
                  surmonter d'éventuels déficits.
                </p>
              </div>
              <div class="tab-pane" id="tab-6">
                <h3>Gestion de la trésorerie</h3>
         

                <p>
                  Notre solution assure une gestion intégrale des informations pharmaceutiques, y compris les données sur
                  les médicaments, les fournisseurs, les stocks, les commandes, et bien d'autres. <br />

                  <br />Le module de gestion pharmaceutique permet également de consolider la base de données
                  pharmaceutique au sein de chaque établissement hospitalier.
                </p>
                <img src="../images/tresor.jpg" alt="" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  -->
    <!-- End Departments Section -->
    <!-- ======= Actor Section ======= -->
    <!-- ======= Actor Section ======= -->
    <section id="actor" class="testimonials">
      <div class="container">
        <div class="section-title">
          <h2>ACTEURS DE MedKey</h2>
        </div>

        <div class="testimonials-slider swiper">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>CENTRE DE SANTE</strong>
                  </h6>
                  <p class="hover text-justify">
                    Un centre de santé désigne une infrastructure de soins de
                    santé qui propose une variété de services médicaux,
                    généralement de nature primaire ou ambulatoire, afin de
                    répondre aux besoins de la communauté locale.
                  </p>
                  <!-- <img src="images/hopital.jpg" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>PERSONNELS MEDICAUX</strong>
                  </h6>
                  <p class="hover text-justify">
                    Le personnel médical regroupe l'ensemble des professionnels de la santé qui exercent leur activité
                    dans divers établissements de soins. Ces établissements incluent les hôpitaux, les cliniques, les
                    cabinets médicaux et d'autres structures.
                  </p>
                  <!-- <img src="images/med.avif" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>PATIENTS</strong>
                  </h6>
                  <!-- <h3>PATIENTS</h3> -->
                  <p class="hover text-justify">
                    Un patient hospitalisé est une personne qui reçoit des soins médicaux dans un environnement
                    hospitalier spécifiquement dédié. Cette hospitalisation est généralement nécessaire en réponse à une
                    maladie, une blessure.

                  </p>
                  <!-- <img src="images/patient.PNG" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>MINISTÈRE DE LA SANTÉ</strong>
                  </h6>
                  <p class="hover text-justify">
                    Le ministère de la santé au Bénin est une entité
                    gouvernementale responsable de la planification, de la
                    réglementation, de la supervision et de la promotion de la
                    santé et des services de santé dans tout le pays.

                  </p>
                  <!-- <img src="images/MS.png" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
          </div>
          <!-- End testimonial item -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- End Testimonials Section -->

    <!-- ======= Contact Section ======= -->
    <!-- <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h2>Contact</h2>
          <p>
            Pour nous contacter, veuillez nous envoyer un message par email ou
            utiliser les numéros de téléphone indiqués ci-dessous pour nous
            appeler.
          </p>
        </div>
      </div>
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <div class="info-box">
                  <i class="bx bx-map"></i>
                  <h3>Notre Adresse</h3>
                  <p>
                    Commune de Lokossa (Mono),
                    BENIN
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <i class="bx bx-envelope"></i>
                  <h3>Email</h3>
                  <p>info@example.com<br />contact@example.com</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <i class="bx bx-phone-call"></i>
                  <h3>Appelez-nous</h3>
                  <p>+229 000000<br />+229 0000000</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Votre Nom" required="" />
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Votre Email"
                    required="" />
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Sujet" required="" />
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="7" placeholder="Message" required=""></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Chargement</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Votre message a été envoyé. Merci!
                </div>
              </div>
              <div class="text-center">
                <button type="submit">Envoyer le message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Section -->
    <!-- <p id="local">Localisation du Ministère de la Santé du BENIN</p> -->

    <!-- <div class="row">
      <iframe id="iframe"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63443.59310936481!2d2.422241972313055!3d6.36497637245072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sbj!4v1685731403629!5m2!1sfr!2sbj"
        allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> -->
  </div>
</template>

<script>
// Déplacez les importations JavaScript ici, à l'intérieur de la section <script>
import "../asset/vendor/purecounter/purecounter_vanilla.js";
import "../asset/vendor/aos/aos.js";
import "../asset/vendor/bootstrap/js/bootstrap.bundle.min.js";
import "../asset/vendor/glightbox/js/glightbox.min.js";
import "../asset/vendor/swiper/swiper-bundle.min.js";
import "../asset/vendor/php-email-form/validate.js";
//import "../asset/js/main.js";
export default {
  name: "FeaturedComponent",
  components: {},
  mounted() {
    /**
     * Testimonials slider
     */
    new swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });

    /**
     * Clients Slider
     */
    new swiper(".gallery-slider", {
      speed: 400,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  },
};
</script>

<style scoped>
section {
  padding: 60px 0;
  overflow: hidden;
}



#local {
  text-align: center;

  color: #053832;
}

#iframe {
  border: 0px;
  width: 100%;
  height: 500px;
}

#featuredImage {
  background-image: url("../asset/img/features.jpg");
}


.statistics {
  display: flex;
  justify-content: space-around;
}

.statistic {
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  flex: 1;
}

.value {
  font-size: 24px;
  font-weight: bold;
}

/* 
.bien:hover{

color: #fff;
} */

/* Au survol de l'élément parent .count-box */
.count-box:hover {
  background-color: #2ea096;
  /* Ajouter un arrière-plan vert */
  color: white;
  /* Changer la couleur du texte en blanc */
}

/* Au survol de l'icône */
.count-box:hover i,
.count-box:hover h6 {
  color: white;
  /* Changer la couleur de l'icône en blanc */
}

.label {
  font-size: 16px;
  margin-top: 10px;
}

.hover:hover {
  transition: 1.2s;
  background-color: #2ea096;
  color: white;
}

@media screen and (max-width: 1200px) {
  .testimonial-item {
    margin-bottom: 0px;
  }
}

/* miniature */
/* .container {
  display: flex;
  justify-content: center;
} */

.row {
  display: flex;
  flex-wrap: wrap;
}

.module-thumbnail {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 2px solid #199187;
  border-radius: 10px;
  width: 100%;
  max-width: 352px;
  transition: transform 0.2s;
}

.green-underline {
  display: block;
  width: 70px;
  /* Largeur du trait */
  height: 4px;
  /* Épaisseur du trait */
  background-color: #199187;
  /* Couleur verte */
  margin: 0 auto;
  /* Centrer horizontalement le trait sous le texte */
  margin-top: 5px;
  /* Espace entre le texte et le trait */
}


.col-md-4,
.col-md-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.module-thumbnail {
  flex-grow: 1;
}


.module-title {
  font-weight: bold;
}

.module-thumbnail:hover {
  transform: scale(1.05);
  background-color: #199187;
}

.module-thumbnail img {
  width: 100px;
  height: 100px;
  transition: filter 0.2s;
  /* Ajout de la transition pour la propriété filter */
}

.module-thumbnail:hover img {
  filter: invert(100%);
  /* Transformation de l'image en couleur blanche au survol */
}

.module-thumbnail p:first-child {
  font-size: 18px;
  margin: 10px 0;
  color: #000;
  transition: color 0.2s;

}

.module-thumbnail p:last-child {
  font-size: 14px;
  color: #000;
  transition: color 0.2s;
}

/* Au survol, le texte passera en blanc */
.module-thumbnail:hover p {
  color: white;
}

@media (max-width: 768px) {
  .module-thumbnail {
    width: 100%;
  }
}



@import "../asset/vendor/fontawesome-free/css/all.min.css";
@import "../asset/vendor/animate.css/animate.min.css";
@import "../asset/vendor/aos/aos.css";
@import "../asset/vendor/bootstrap-icons/bootstrap-icons.css";
@import "../asset/vendor/boxicons/css/boxicons.min.css";
@import "../asset/vendor/glightbox/css/glightbox.min.css";
@import "../asset/vendor/swiper/swiper-bundle.min.css";
@import "../asset/css/style.css";
</style>
